import Storage from "@/utils/storage";
import { handleToken } from "@/utils/Foundation";
import axios from "axios";
import { Toast } from "vant";
import Vue from "vue";
import store from "@/store";

const qs = require("qs");

Vue.use(Toast);

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
  // paramsSerializer: (params) => {
  // 在请求拦截中配置时间戳需求添加每次请求的时间戳
  //   params.time = new Date().getTime();
  //   return qs.stringify(params, { arrayFormat: "repeat" });
  // },
});

service.interceptors.request.use(
  (config) => {
    let { loading } = config.headers;
    let accessToken = Storage.getItem("token") || store.state.jwt;
    if(!accessToken) {
      var token = Cookies.get("token");
      accessToken = token;
      this.$store.dispatch("setJwt", token);
    }
    var type = config.method;
    if (type == "get") {
      var timestamp = new Date().getTime();
      config.url +=
        config.url.indexOf("?") !== -1
          ? `&timestamp=${timestamp}`
          : `?timestamp=${timestamp}`;
    }
    // 是否需要loading
    if (loading !== false) {
      config.loading = Toast.loading({
        overlay: true,
        duration: "0",
        forbidClick: true,
      });
    }
    if (accessToken && config.headers.needToken) {
      config.headers["Authorization"] = accessToken;
    }

    // config.data = {
    // 在请求拦截中配置时间戳需求添加每次请求的时间戳
    //   ...(config.data || {}),
    //   time: new Date().getTime(),
    // };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  async (response) => {
    await closeLoading(response);
    // 未设置状态码则默认成功状态
    const code = response.data.code || 200;
    if (code === 200) {
      return response.data;
    }

    if (response.config.url.includes('h5ApplyV2') || response.config.url.includes('saveApplyData')) {
      return response.data;
    } else {

      if (code === 401) {
        Toast("请先登录");  
      } else {
        Toast(response.data.msg);
      }
    }
  },
  async (error) => {
    await closeLoading(error);
    return Promise.reject(error);
  }
);

export default function request(options) {
  return new Promise((resolve, reject) => {
    if (
      options.headers &&
      options.headers.needToken &&
      options.headers.device
    ) {
      handleToken().then(async (token) => {
        await store.dispatch("setToken", token);
        service(options).then(resolve).catch(reject);
      });
    } else {
      service(options).then(resolve).catch(reject);
    }
  });
}

/**
 * 关闭全局加载
 * @param target
 */
const closeLoading = (target) => {
  if (!target.config || !target.config.loading) return true;
  return new Promise((resolve) => {
    setTimeout(() => {
      Toast.clear();
      resolve();
    }, 200);
  });
};
